<template>
    <div class="min-vh-100 pt-3 mb-3">
        {{properties.title}}
        <div>
            <b-nav pills vertical class="w-auto pt-3 bg-white">
                <div v-for="(item, index) in properties.items">
                    <b-nav-item :active="item.active" :to="item.path" :disabled="item.disabled">
                        <span v-if="options.numbering">{{index+1}}.</span>
                        {{item.text}}
                    </b-nav-item>
                </div>
            </b-nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SiderMenuItems_deprecated",
        components: {},
        props: {
            element: {}
        },
        data() {
            return {
                properties: {},
                options: {
                    sorted: false,
                    numbering: false,
                },
            }
        },
        mounted() {
            this.properties = this.element.properties;
            this.options = this.element.options;
            if (this.options.sorted) {
                this.properties.items = this.sortedByText();
            }
            this.setActive();
        },
        methods: {
            sortedByText() {
                return this.properties.items.sort((t1, t2) => t1.text < t2.text ? -1 : 1);
            },
            setActive() {
                let route = this.$router.currentRoute.path;
                this.properties.items.forEach((item, index) => {
                    item.active = item.path === route ? true : false;
                });
            }
        },
        computed: {},
        filters: {},
        watch: {
            $route(to, from) {
                this.setActive();
            }
        }
    }
</script>

<style scoped>

</style>
