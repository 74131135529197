<template>
    <div>
        <b-row no-gutters>
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items :element="element"><</sider-menu-items>
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SiderMenuItems from "../../general/elements/menus/SiderMenuItems_deprecated";
// @group Form.REGISTER
export default {
    name: "registerContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            element: {
                properties: {
                    title: null,
                    items: [
                        {
                            text: this.$tc('register.bank', 2),
                            path: '/register/banks',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.bank_purpose_code', 2),
                            path: '/register/bank_purpose_codes',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.continent', 2),
                            path: '/register/continents',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.currency', 2),
                            path: '/register/currencies',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.requirement', 2),
                            path: '/register/requirements',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.document_type', 2),
                            path: '/register/document_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.event_type', 2),
                            path: '/register/event_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.offer_type', 2),
                            path: '/register/offer_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.partnership_type', 2),
                            path: '/register/partnership_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.premises_type', 2),
                            path: '/register/premises_types',
                            disabled: true,
                            active: false,
                        },
                        {
                            text: this.$tc('register.organisation_group', 2),
                            path: '/register/organisation_groups',
                            disabled: true,
                            active: false,
                        },
                        {
                            text: this.$tc('register.organisation_type', 2),
                            path: '/register/organisation_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.packing', 2),
                            path: '/register/packaging',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.post_office', 2),
                            path: '/register/post_offices',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.production_type', 2),
                            path: '/register/production_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.region', 2),
                            path: '/register/regions',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.state', 2),
                            path: '/register/states',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.tax_rate', 2),
                            path: '/register/tax_rates',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.taxpayer_type', 2),
                            path: '/register/tax_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.transaction_type', 2),
                            path: '/register/transaction_types',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.unit_basic', 2),
                            path: '/register/units_basic',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.unit_group', 2),
                            path: '/register/unit_groups',
                            disabled: false,
                            active: false,
                        },
                        {
                            text: this.$tc('register.tag', 1),
                            path: '/register/tags',
                            disabled: true,
                            active: false,
                        },
                        {
                            text: this.$tc('register.suitable', 1),
                            path: '/register/suitable',
                            disabled: true,
                            active: false,
                        },
                        {
                            text: this.$tc('register.guest_type', 1),
                            path: '/register/guest_types',
                            disabled: true,
                            active: false,
                        },

                        // <-- deprecated -->
                        // {
                        //     text: 'Partnerji',
                        //     path: '/register/partners',
                        //     disabled: true,
                        //     active: false,
                        // },
                        // {
                        //     text: 'Prazniki',
                        //     path: '/register/holidays',
                        //     disabled: true,
                        //     active: false,
                        // },
                        // {
                        //     text: 'Certifikati',
                        //     path: '/register/certificates',
                        //     disabled: true,
                        //     active: false,
                        // },
                    ]
                },
                options: {
                    sorted: true,
                    numbering: true,
                },
            }
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
